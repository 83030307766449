/* You can add global styles to this file, and also import other style files */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "~@fortawesome/fontawesome-free/css/all.min.css";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* font */
//@import "typeface-montserrat/index.css";
@import url("https://fonts.googleapis.com/css2?family=Quantico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syne:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

.font-syne {
  font-family: "Syne", sans-serif; /* Apply Syne font with fallback to sans-serif */
}

.font-inter {
  font-family: "Inter", sans-serif; /* Apply Syne font with fallback to sans-serif */
}

// google icons
// Test to help icon load
.material-symbols-outlined {
  font-weight: 200; /* Adjust the font weight */
  font-optical-sizing: 20; /* Adjust the optical size */
}

//Prevent global CSS being applied a component in Angular 5
.component-container {
  all: initial;
  all: unset;
}

// prevent scrollbar from repositioning web page
html {
  overflow-y: scroll;
}

// Override mat-snack-bar styling
.mdc-snackbar__surface {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.mdc-snackbar__label {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: transparent !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

// Text in price-calculator price comparison
.quantico-text {
  font-family: "Quantico", sans-serif;
  background: linear-gradient(0.25turn, #34c0a7, #06e79b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-bg {
  background: linear-gradient(0.25turn, #34c0a7, #06e79b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// quantico font
.quantico-font {
  font-family: "Quantico", sans-serif;
}

// FOR SLIDER -
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #ffff;
  --mdc-slider-active-track-color: #34c0a7;
  --mdc-slider-focus-handle-color: #ffff;

  --mat-mdc-slider-focus-ripple-color: transparent;
  --mat-mdc-slider-hover-ripple-color: transparent;
}
